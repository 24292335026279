import axios from 'axios';
import { loginHandlerForMobile, slientLoginHandlerForMobile } from './validator';
import { antdMessageError } from '../Components/common/antdMessageComponent';
import { removeDuplicateParams } from '../Components/user/utills';

export async function handleAuthCodeGeneration(
  code,
  redirectUrl,
  commanUrlValue,
  clientId,
  navigate,
  clientDetails,
  SHOWPOP,
  popUpId,
  siteUrl,
  skip,
) {
  // alert(SHOWPOP);
  // return;
  const queryParams = new URLSearchParams(window.location.search);
  const inAppPurchase = queryParams.get('inAppPurchase');
  try {
    const codedata = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
      headers: { Authorization: `Bearer ${code}` },
    });
    console.log(redirectUrl, 'testing123');

    function redirect() {
      if (redirectUrl) {
        if (redirectUrl === 'https://conscent-app-sdk') {
          try {
            const dataformobile = {
              token: codedata.data.authCode,
              status: true,
            };
            loginHandlerForMobile(JSON.stringify(dataformobile));
          } catch (err) {
            console.log(err, 'error while sending data to mobile app');
          }
        } else {
          slientLoginHandlerForMobile(
            JSON.stringify({
              message: 'SLIENT_USER_LOGIN',
              authToken: codedata.data.authCode,
              statusCode: '200',
            }),
          );
          const fetchRedirectUrl = new URL(redirectUrl);
          fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
          fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
          fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);
          if (!fetchRedirectUrl.searchParams.has('clientId') && clientId) {
            fetchRedirectUrl.searchParams.set('clientId', clientId);
          }
          try {
            if (!fetchRedirectUrl.searchParams.has('mobileView') && queryParams.get('mobileView')) {
              fetchRedirectUrl.searchParams.set('mobileView', queryParams.get('mobileView'));
            }
            if (inAppPurchase) {
              fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
              fetchRedirectUrl.searchParams.set('tierId', queryParams.get('tierId'));
              fetchRedirectUrl.searchParams.set('productId', queryParams.get('productId'));
              fetchRedirectUrl.searchParams.set('subsId', queryParams.get('subsId'));
            }
          } catch (error) {
            console.error(error);
          }
          window.location.href = fetchRedirectUrl.href;
          // }
        }
      } else {
        slientLoginHandlerForMobile(
          JSON.stringify({
            message: 'SLIENT_USER_LOGIN',
            authToken: codedata.data.authCode,
            statusCode: '200',
          }),
        );
        const fetchRedirectUrl = new URL(redirectUrl);
        fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
        fetchRedirectUrl.searchParams.set('paywallId', commanUrlValue.paywallId);
        fetchRedirectUrl.searchParams.set('anonId', commanUrlValue.anonId);
        if (!fetchRedirectUrl.searchParams.has('clientId') && clientId) {
          fetchRedirectUrl.searchParams.set('clientId', clientId);
        }
        try {
          if (!fetchRedirectUrl.searchParams.has('mobileView') && queryParams.get('mobileView')) {
            fetchRedirectUrl.searchParams.set('mobileView', queryParams.get('mobileView'));
          }
          if (inAppPurchase) {
            fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
            fetchRedirectUrl.searchParams.set('tierId', queryParams.get('tierId'));
            fetchRedirectUrl.searchParams.set('productId', queryParams.get('productId'));
            fetchRedirectUrl.searchParams.set('subsId', queryParams.get('subsId'));
          }
        } catch (error) {
          console.error(error);
        }
        window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
        // }
      }
    }
    async function callPopUpFunction() {
      // alert('skjdhikl');
      window.CloseFrame = function () {
        //@ts-ignore
        if (skip === 'true') {
          document.getElementById('popUp-container').style.display = 'none';
          redirect();
        } else {
          window.location.href = siteUrl;
        }
      };
      //@ts-ignore

      window.skip = function () {
        document.getElementById('popUp-container').style.display = 'none';
        redirect();
      };
      let iframe = document.createElement('iframe');
      const popUpData = await fetch(`${process.env.REACT_APP_API_URL}/client/popup/${popUpId}`, {
        method: 'GET',
      }).then((res) => res.json());
      // const html = `<!DOCTYPE html><html><head><title>Page Title</title></head><body><div><h1>This is a new2</h1><p onClick="window.parent.skip()">This is a paragraph.</p></div></body></html>`;
      const html = popUpData.content;
      iframe.style.border = 'none';
      iframe.style.margin = '0px';
      iframe.style.padding = '0px';
      iframe.style.background = 'transparent';
      iframe.style.width = '100vw';
      iframe.style.height = '100vh';
      iframe.setAttribute(
        'srcdoc',
        `<html><head>
         
        </head><body>
         ${html}
          </body>
        
          </html> `,
      );
      var innerDiv = document.createElement('div');
      innerDiv.className = 'inner-div-popUp';
      innerDiv.style.position = 'fixed';
      innerDiv.style.left = '0px';
      innerDiv.style.zIndex = 2147483599;
      innerDiv.style.width = '100vw';
      innerDiv.style.top = '0px';

      innerDiv.style.display = 'flex';
      innerDiv.style.justifyContent = 'center';
      innerDiv.style.alignItems = 'center';
      const wrappingElement = document.createElement('div');
      wrappingElement.setAttribute('id', `popUp-container`);
      document.body.appendChild(wrappingElement);
      wrappingElement.append(innerDiv);
      innerDiv.appendChild(iframe);
    }
    // alert()
    if (SHOWPOP === 'true') {
      await callPopUpFunction();
    } else {
      redirect();
    }
  } catch (err) {
    console.log(err, 'error on generating auth code');
  }
}
