import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../Style/transaction.module.css';
import Arrow from './icons/back Vector';
import Download from './icons/download';
import axios from 'axios';
import { Spin } from 'antd';
import { antdMessageError, antdMessageSuccess } from '../common/antdMessageComponent';
import { logOutHandlerForMobile } from '../../utills/validator';

const Transaction = ({ setscreen, rightvaluepassed, color }) => {
  const navigate = useNavigate();

  const textColor = "#00063d"
  const backgroundColor = "#f5f5ff"
  const highlightColor = "#7583ff"
  const fontstyle = "poppins"

  const [data, setdata] = useState();
  const [rightvalue, setrightvalue] = useState();
  const [transaction, setTransaction] = useState([]);
  const [purchaseId, setPurchaseId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')

  const handleRedirect = (path) => {
    navigate(path);
  };

  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);

  const fetchData = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setdata(response.data);
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError("It seems your account has been logged out. Please try logging in again")
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    // Define options for toLocaleDateString
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    // Convert to the desired format
    return date.toLocaleDateString('en-GB', options);
  }


  useEffect(() => {
    fetchData();
  }, []);

  const downloadinvoice = async (purchaseId, transactionId, type, title) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/download-invoice?purchaseId=${purchaseId}&transactionId=${transactionId}&type=${type}&title=${title}`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      if (response.status === 200) {
        downloadPDF(response?.data?.attachments?.content, response?.data?.attachments?.filename);
        antdMessageSuccess('downloaded Successfully')
      }
    } catch (error) {
      antdMessageError(error.message)
      console.log(error.message);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64); // Decode base64 string to binary
    const len = binaryString.length;
    const bytes = new Uint8Array(len); // Create an array buffer of the binary string
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i); // Convert binary string to bytes
    }
    return bytes.buffer;
  };

  // Create and download the PDF file
  const downloadPDF = (baseblob, filename) => {
    const arrayBuffer = base64ToArrayBuffer(baseblob); // Decode base64
    const blob = new Blob([arrayBuffer], { type: "application/pdf" }); // Create a blob
    const link = document.createElement("a"); // Create a temporary link element
    link.href = window.URL.createObjectURL(blob); // Create a link to the blob
    link.download = filename; // Set the download file name
    link.click(); // Programmatically trigger a click to download the file
  };

  const [font, setfont] = useState('20px');

  const handleType = (type) => {
    if (type === 'CONTENT') {
      return 'Pay-per-article';
    } else if (type === 'PASS') {
      return 'Pay-per-use';
    } else if (type === 'SUBSCRIPTION') {
      return 'Subscription';
    }
  }

  const handleTitleLength = (title) => {
    if (title?.length > 45) {
      return `${title.substring(0, 45)}...`
    } else { return title }
  }
  const getTransactions = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/userTransactions?page=1&limit=999`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      setTransaction(response.data.userTransactions)
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerposition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue();
    }
  }, [data]);

  useEffect(() => {
    fetchData();
    getTransactions();
  }, []);

  return (
    <>

      {loading ? (
        <div
          className={style['outercontainer']}
          style={{
            background: color,
            right: rightvaluepassed,
          }}>

          <div className={style['loader-container']}>
            <div className={style['loader']}></div>
          </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvaluepassed,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div className={style['head']} style={{ borderBottom: `0.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}>
            <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
              <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
            </div>
            <div className={style['topic']}>Transactions Details</div>
          </div>

          <div className={style['content']}>

            {
              transaction.map(item => (
                <div className={style['details']} style={{ borderBottom: `0.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}>
                  <div className={style['date']}>
                    {/* <span>Date : {item.date.substring(0,10)}</span> */}
                    <span>Date : {formatDate(item?.date)}</span>
                    {/* <span className={style['dwn']} onClick={() => downloadinvoice(item?._id, item?.transactionId, item?.type, item?.name?.title)} style={{ color: data?.userProfileCustomization?.highlightColor || highlightColor, display: 'flex', alignItems: 'center' }}>
                      <Download fill={data?.userProfileCustomization?.highlightColor || highlightColor} /> <span>Download invoice</span>
                    </span> */}
                  </div>
                  <div className={style['price']}>
                    <div className={style['name']}>
                      <div className={style['type']} style={{ border: `0.5px solid ${data?.userProfileCustomization?.highlightColor || highlightColor} `, color: data?.userProfileCustomization?.highlightColor || highlightColor, fontSize: '12px', fontWeight: '400', padding: '2px 8px', borderRadius: '2px', width: 'fit-content' }}>
                        {handleType(item.type)}
                      </div>
                      <div className={style['planname']} style={{ lineHeight: '20px', fontWeight: '400', fontsize: '14px', wordWrap: 'wrap' }}>
                        {/* {handleTitleLength('Artificial intelligence breakthroughs create new ‘brain’ for advanced robots')} */}
                        {handleTitleLength(item?.name?.title)}
                      </div>
                    </div>

                    <div className={style['cost']} style={{ fontSize: font }}>
                      {`${item.currency} ${item.price}`}
                    </div>
                  </div>
                  <div className={style['payment']}>
                    <div className={style['paymenttype']}>Payment type :</div>
                    <div className={style['actualvalue']}>{item?.paymentMode}</div>
                  </div>
                </div>
              ))
            }


            {/* {data.map(item => (
            <div key={item.id} className="card">
          <h2>{item.title}</h2>
          <p>{item.description}</p>
            </div>
            ))} */}







          </div>
        </div>
      )}
    </>
  );
};

export default Transaction;
